import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import icon from '../image/uitm_universiti_teknologi_mara_logo.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleRegistration = this.toggleRegistration.bind(this);
        this.toggleResult = this.toggleResult.bind(this);
        this.toggleHome = this.toggleHome.bind(this);
        this.toggleMore = this.toggleMore.bind(this);

        this.state = {
            collapsed: true,
            registrationOpen: false,
            resultOpen: false,
            homeOpen: false,
            moreOpen:false

        };
    }
    toggleRegistration() {
        this.setState({
            registrationOpen: !this.state.registrationOpen
        });
    }
    toggleResult() {
        this.setState({
            resultOpen: !this.state.resultOpen
        });
    }
    toggleHome() {
        this.setState({
            homeOpen: !this.state.homeOpen
        });
    }
    toggleMore() {
        this.setState({
            moreOpen: !this.state.moreOpen
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    
                    <NavbarBrand tag={Link} to="/"><img class="logo" src={icon} alt="icon" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <Dropdown nav isOpen={this.state.homeOpen} toggle={this.toggleHome} style={{ paddingRight: "15px" }}>
                                <DropdownToggle nav caret className="text-dark" to="/#/" style={{ fontSize: 16 }}>
                                    <strong>HOME</strong>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="/#/#aboutus">ABOUT US</DropdownItem>
                                    <DropdownItem href="/#/#category">CATEGORY</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown nav isOpen={this.state.registrationOpen} toggle={this.toggleRegistration} style={{ paddingRight: "15px" }}>
                                <DropdownToggle nav caret className="text-dark" to="/#/call-for-registration" style={{ fontSize: 16 }}>
                                    <strong>CALL FOR REGISTRATION</strong>
                                    {/*<p className="blink"*/}
                                    {/*    style={{ color: "red", display: 'inline', fontSize: 12, marginLeft: 5 }}>*/}
                                    {/*    NEW*/}
                                    {/*</p>*/}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="/#/call-for-registration#competitionguidelines">COMPETITION GUIDELINES</DropdownItem>
                                    <DropdownItem href="/#/call-for-registration#registrationsubmission">REGISTRATION AND SUBMISSION GUIDELINES 
                                    </DropdownItem>
                                    <DropdownItem href="/#/call-for-registration#importantdates">IMPORTANT DATES</DropdownItem>
                                    <DropdownItem href="/#/call-for-registration#registrationfees">REGISTRATION FEES
                                        {/*<p className="blink"*/}
                                        {/*    style={{ color: "red", display: 'inline', fontSize: 12, marginLeft: 5 }}>*/}
                                        {/*    NEW*/}
                                        {/*</p>*/}
                                    </DropdownItem>
                                    <DropdownItem href="/#/call-for-registration#awardandprize">AWARDS AND PRIZES</DropdownItem>
                                    <DropdownItem href="/#/call-for-registration#categorycomparison">CATEGORY COMPARISON</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem style={{ paddingRight: "25px" }}>
                                <NavLink tag={Link} className="text-dark" to="/call-for-jury" style={{ fontSize: 16 }}><strong>CALL FOR JURY/REVIEWER</strong>
                                    {/*<p className="blink"*/}
                                    {/*    style={{ color: "red", display: 'inline', fontSize: 12, marginLeft: 5 }}>*/}
                                    {/*    NEW*/}
                                    {/*</p>*/}
                                </NavLink>
                            </NavItem>
                            <Dropdown nav isOpen={this.state.resultOpen} toggle={this.toggleResult} style={{ paddingRight: "25px" }}>
                                <DropdownToggle nav caret className="text-dark" to="/#/result" style={{ fontSize: 16 }}>
                                    <strong>RESULTS</strong>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="/#/result#award">AWARDS
                                        <p className="blink"
                                            style={{ color: "red", display: 'inline', fontSize: 12, marginLeft: 5 }}>
                                            NEW
                                        </p>
                                    </DropdownItem>
                                    <DropdownItem href="/#/result#publication">PUBLICATION</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown nav isOpen={this.state.moreOpen} toggle={this.toggleMore} style={{ paddingRight: "25px" }}>
                                <DropdownToggle nav caret className="text-dark" to="/#/more" style={{ fontSize: 16 }}>
                                    <strong>MORE</strong>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="/#/more#download">DOWNLOAD
                                        <p className="blink"
                                            style={{ color: "red", display: 'inline', fontSize: 12, marginLeft: 5 }}>
                                            NEW
                                        </p>
                                    </DropdownItem>
                                    <DropdownItem href="/#/more#postereventtentative">POSTER AND EVENT TENTATIVE</DropdownItem>
                                    <DropdownItem href="/#/more#organisingcommittee">ORGANISING COMMITTEE</DropdownItem>
                                    <DropdownItem href="/#/more#visitjohor">VISIT JOHOR</DropdownItem>
                                    <DropdownItem href="/#/more#jiiicasfaq">JIIICaS2024 FAQ</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </ul>                   
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
